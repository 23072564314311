/* MyStripoPlugin.css */

#externalSystemContainer {
  background-color: darkgrey;
  padding: 5px 0 5px 20px;
}

#undoButton,
#redoButton {
  display: none;
}

#stripoSettingsContainer {
  width: 400px;
  float: left;
}

#stripoPreviewContainer {
  width: calc(100% - 400px);
  float: left;
}

.notification-zone {
  position: fixed;
  width: 400px;
  z-index: 99999;
  right: 20px;
  bottom: 80px;
}

.control-button {
  border-radius: 17px;
  padding: 5px 10px;
  border-color: grey;
}

#changeHistoryLink {
  cursor: pointer;
}
.esdev-app .sripo-watermark {
  display: none !important;
}
.esdev-app .nav-tabs.nav-justified > li {
  display: contents !important;
}

.stripo {
  height: calc(100vh - 250px);
  overflow-y: auto;
  margin-bottom: 30px;
}
