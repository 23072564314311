@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800;900&display=swap');

body {
  margin: 0;
  font-family: 'Inter', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  text-decoration: unset !important;
  cursor: pointer;
}

ul {
  padding-left: 0 !important;
  list-style-type: none;
  margin-bottom: 0 !important;
}

.form-group {
  margin-bottom: 30px;
}

.underline {
  text-decoration: underline !important;
}

input.form-control {
  border-radius: 6px;
  border: 1px solid #E6E6E6;
  padding: 16px 16px;
  font-size: 16px;
  color: #000;
}

.date-time-picker input.form-control {
  border: 1px solid #13294B;
  background-color: transparent;
}

input.form-control::placeholder {
  font-size: 16px;
  color: #878787;
}

select.form-select {
  border-radius: 6px;
  border: 1px solid #E6E6E6;
  padding: 16px 16px;
  font-size: 16px;
  color: #000;
}

label {
  font-size: 18px;
  color: #13294B;
  margin-bottom: 12px !important;
}

/* ------------------ login --------------------------- */
.card-bg {
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url('../../frontend/src/assets/images/BG.png');
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-position: center center;
  padding: 20px;
  min-height: 100vh;
}

.login .card {
  border-radius: 40px;
  max-width: 1320px;
  box-shadow: 0px 0px 34px 0px rgba(0, 0, 0, 0.12);
  overflow: hidden;
  border: unset;
  background-color: unset;
}

.login .title {
  font-size: 44px;
  font-weight: 600;
  color: #fff;
  font-family: 'Kanit', sans-serif;
  margin-bottom: 0;
}

.bg-blue {
  background: #7BAFD4;
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.bg-white {
  background: #fff;
}

.text-blue {
  color: #7BAFD4;
}

.text-darkBlue {
  color: #13294B;
}

.lt-text {
  padding: 20px 24px;
}

.lt-text img {
  margin-bottom: 44px;
}

.rt-content {
  padding: 80px 76px 30px;
}

.rt-content img {
  display: block;
  margin: auto;
  margin-bottom: 56px;
}

.rt-content .heading-title {
  margin-bottom: 40px;
}

.rt-content .heading-title h2 {
  font-size: 32px;
  font-weight: 500;
  text-align: center;
  font-family: 'Kanit', sans-serif;
  color: #13294B;
}

.rt-content .heading-title {
  margin-bottom: 40px;
}

.rt-content .heading-title p {
  text-align: center;
  color: #878787;
}

input.form-check-input {
  border-radius: 4px !important;
  border: 1px solid #E6E6E6;
  margin-right: 10px;
  width: 24px;
  height: 24px;
}

.form-check-input:focus {
  box-shadow: unset !important;
}

.box-check p {
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 0;
}

.box-check {
  margin-bottom: 60px;
}

.form-check label {
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 0 !important;
}

.form-check-input:checked {
  background-color: #13294b !important;
  border-color: #13294b !important;
}

.btn-theme {
  border-radius: 8px !important;
  border: 1px solid #13294B !important;
  background: #13294B !important;
  padding: 14px 30px !important;
  font-size: 18px !important;
  font-weight: 500 !important;
  color: #fff !important;
}

.btn-border {
  background: transparent !important;
  color: #13294B !important;
}

.btn-no-border {
  background: transparent !important;
  border: unset !important;
  color: #13294B !important;
}

.btn-theme:hover {
  background: #fff !important;
  color: #13294B !important;
}

.btn-theme:hover svg {
  color: #13294B !important;
}

.btn-border:hover svg {
  color: #fff !important;
}

.btn-border:hover {
  background: #13294B !important;
  color: #FFF !important;
}

.btn-theme.btn-red {
  background: #CB0101 !important;
  border: 1px solid #CB0101 !important;
}

.btn-theme.btn-red:hover {
  color: #fff !important;
}

.btn-theme.btn-green {
  background: #34BD3A !important;
  border: 1px solid #34BD3A !important;
}

.btn-theme.btn-green:hover {
  color: #fff !important;
}

.btn-theme.btn-red:hover svg {
  color: #fff !important;
}

.input-form {
  margin-bottom: 110px;
}

.btm-text {
  color: #000;
}

.btm-text a {
  text-decoration: underline;
  font-weight: 600;
}

.eye-icon {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 18px;
}

.eye-icon path {
  fill: #13294B;
}


/* ------------------ login --------------------------- */

/* ------------------ signup --------------------------- */

.signup-process {
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url('../../frontend/src/assets/images/BG-Signup.png');
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  padding: 20px;
  /* background-position: center center; */
  min-height: 100vh;
}

.signup-process .card {
  border-radius: 40px;
  width: 1320px;
  box-shadow: 0px 0px 34px 0px rgba(0, 0, 0, 0.12);
  overflow: hidden;
  border: unset;
  background-color: unset;
  padding: 40px;
  background-color: #fff;
}

.signup-process form .row {
  margin-bottom: 40px;
}

h2.card-title {
  font-size: 32px;
  font-weight: 500;
  font-family: 'Kanit', sans-serif;
  color: #13294B;
  text-align: center;
  margin-bottom: 40px;
}

.top-logo {
  display: block;
  margin: 0 auto;
  margin-bottom: 40px;
}

.stepper {
  margin-bottom: 60px;
}

.signup-process .stepper {
  padding: 0 140px;
}

.stepper li {
  display: inline-block;
  margin-right: 30px;
}

.stepper li:last-child {
  margin-right: 0;
}

.stepper .step {
  background: #fff;
  border: 1px solid #c9c5c5;
  min-width: 48px;
  height: 48px;
  text-align: center;
  line-height: 44px;
  border-radius: 100%;
  font-size: 24px;
  font-family: 'Kanit', sans-serif;
  color: #e4dcdc;
}

.stepper .step-name {
  font-size: 20px;
  font-weight: 500;
  color: #13294B;
  margin-top: 14px;
  color: #E6E6E6;
}

.stepper span.border-line {
  border-bottom: 2px dashed #E6E6E6;
  height: 7px;
  display: inline-block;
  width: 240px;
  bottom: 4px;
  position: relative;
}

.stepper .active .step {
  border: 1px solid #13294B;
  color: #13294B;
}

.stepper .active .step-name {
  color: #13294B;
}

.stepper .done .step {
  border: 1px solid #13294B;
  background: #13294B;
}

.stepper .done .step-name {
  color: #13294B;
}

.stepper .active span.border-line {
  border-bottom: 2px dashed #13294b;
}

.form-title {
  margin-bottom: 30px;
}

.form-title h2 {
  font-size: 24px;
  font-family: 'Kanit', sans-serif;
  color: #13294B;
}

.form-title p {
  color: #878787;
}

.file-upload {
  position: absolute;
  bottom: 0;
  margin-bottom: 0 !important;
  opacity: 0;
}

.upload-icon {
  position: absolute;
  top: 58px;
  right: 20px;
  font-size: 18px;
}

.upload-icon path {
  fill: #13294B;
}

.signup-process .card.verify {
  max-width: 870px;
  padding: 140px 120px;
}

.card.verify img {
  width: 165px;
  display: block;
  margin: 0 auto;
  margin-bottom: 40px;
}

.card.verify .form-title h2 {
  font-size: 32px;
  margin-bottom: 20px;
}

.card.verify .form-title p {
  font-size: 18px;
  line-height: 34px;
}

.btn-theme svg {
  margin-left: 10px;
  font-size: 14px;
}

.btn-border svg {
  margin-right: 10px;
  margin-left: 0;
  transform: rotate(-180deg);
}

.form-control.is-invalid, .was-validated .form-control:valid {
  background-image: none !important;
}

.form-select {
  background-image: none !important;
}

/* ------------------ signup --------------------------- */


/* ------------------ header --------------------------- */
.navbar {
  background-color: #F1F4FB;
  padding: 10px 14px !important;
}

.navbar-brand img {
  margin-left: 40px;
}

.navbar .dropdown-toggle.nav-link {
  font-size: 20px;
  color: #13294B;
  font-weight: 500;
}

.navbar .search-bar {
  margin-right: 30px;
}

.navbar .search-bar input.form-control {
  border-radius: 6px;
  border: unset;
  padding: 12px 16px 12px 44px;
  font-size: 18px;
  color: #000;
  margin-bottom: 0;
  width: 392px;
}

.navbar .search-bar svg {
  position: absolute;
  top: 18px;
  left: 16px;
  color: #13294B;
}

.select-location {
  margin-right: 30px;
}

.select-location select.form-select {
  border-radius: 6px;
  border: unset;
  padding: 12px 16px;
  font-size: 16px;
  color: #000;
  margin-bottom: 0;
  width: 218px;
}

.select-location label {
  margin-bottom: 0 !important;
  margin-right: 16px;
}

.profile img {
  margin-right: 4px;
}

/* ------------------ header --------------------------- */

/* ------------------ sidebar --------------------------- */
.sidebar {
  width: 395px;
  background: #13294B;
  padding: 20px 30px;
  position: fixed;
  overflow-y: scroll;
  height: 100%;
  top: 100px;
  padding-bottom: 100px;
}

.sidebar::-webkit-scrollbar {
  width: 10px;
}

.sidebar::-webkit-scrollbar-track {
  background-color: #e4e4e4;
}

.sidebar::-webkit-scrollbar-thumb {
  background-color: #7BAFD4;
  border-radius: 100px;
}

.offcanvas-header .btn-close {
  margin-right: 8px !important;
}

.offcanvas-header {
  align-items: flex-start !important;
}

.offcanvas-body .sidebar {
  top: 101px;
}

/* start */
.sidebar .accordion-item {
  background-color: unset;
  border: unset;
}
.sidebar .accordion-button {
  background-color: unset;
  border: unset;
  color: #fff;
  /* padding: 20px 24px;
  margin-bottom: 6px;
  font-size: 18px;
  font-weight: 500; */
  padding: 0;
}

.sidebar .accordion-button::after {
  margin-right: 10px;
}

.sidebar .accordion-button a {
  background-color: unset;
  border: unset;
  color: #fff;
  padding: 20px 24px;
  margin-bottom: 6px;
  font-size: 18px;
  font-weight: 500;
  width: 100%
}

.sidebar .accordion-button:not(.collapsed) {
  background-color: #7BAFD4;
  box-shadow: unset;
  border-radius: 14px !important;
  color: #fff;
}

.sidebar .accordion-button svg {
  margin-right: 20px;
}

.sidebar .accordion-item a {
  color: #fff;
}
.sidebar .accordion-item.active {
  background-color: #7BAFD4;
  box-shadow: unset;
  border-radius: 14px !important;
  color: #fff;
  padding-bottom: 20px;
}

.sidebar .accordion-item.active ul.sub-list {
  padding-top: 0px;
}

.sidebar .accordion-item.active ul.sub-list li.active-child a {
  color: #fff;
  font-weight: 800;
}

.sidebar .accordion-item.active {
  background: #7BAFD4;
}

.accordion-button:focus {
  box-shadow: unset !important;
}

.sidebar ul.sub-list {
  padding-left: 60px !important;
  padding-top: 10px;
}

.sidebar ul.sub-list li {
  margin-bottom: 20px;
  padding: 0;
}

.sidebar ul.sub-list li:last-child {
  margin-bottom: 0;
}

.sidebar ul.sub-list li a {
  color: #8994A5;
}

.sidebar ul.sub-list li.active-child a {
  color: #fff;
  font-weight: 800;  
  text-decoration: underline !important;
}
.custom-accordion-button:not(.collapsed):empty::after {
  opacity: 0;
}

.custom-accordion-button.no-children::after {
  opacity: 0;
}

/* You may need additional styles to remove the right icon */
.custom-accordion-button.no-children::after {
  content: none; /* Assuming the right icon is added via CSS content */
}

/* .accordion-button:not(.collapsed)::after {    
  opacity: 0;
} */

 /* start */


/* .sidebar ul li {
  padding: 20px 24px;
  border-radius: 14px;
  margin-bottom: 6px;
}

.sidebar ul li:last-child {
  margin-bottom: 0;
}

.sidebar ul li svg {
  margin-right: 20px;
}

.sidebar ul li span {
  font-size: 20px;
  color: #fff;
  font-weight: 500;
}

.sidebar .active {
  background: #7BAFD4;
}

.show-dropdown {
  padding-left: 38px;
  padding-top: 8px;
}

.sidebar .show-dropdown a.active-child {
  text-decoration: underline !important;
  font-weight: 700;
  color: #fff !important;
}

.sidebar li.active .show-dropdown a {
  color: #fff !important;
}

.sidebar li.active .show-dropdown:nth-child(2) a {
  text-decoration: underline !important;
  font-weight: 700;
}

.sidebar li:has(.active-child) {
  background: #7BAFD4;
}

.sidebar li:has(.active-child) a {
  color: #fff !important;
}

.show-dropdown a {
  display: block;
  font-size: 18px;
  color: #8994A5 !important;
  margin-bottom: 26px;
}

.show-dropdown a:last-child {
  margin-bottom: 0;
} */

/* ------------------ sidebar --------------------------- */

/* ------------------ dashboard --------------------------- */
.main-wrapper {
  width: calc(100% - 395px);
  margin-left: 395px;
  margin-top: 100px;
  padding: 30px;
}

.dashboard .main-title {
  margin-bottom: 30px;
}

.dashboard .main-title h2 {
  font-family: 'Kanit', sans-serif;
  font-size: 32px;
  font-weight: 500;
}

.dashboard .main-title .rt-btns button {
  margin-right: 16px;
}

.dashboard .main-title svg.fa-play {
  transform: rotate(-180deg);
  margin-right: 8px;
}

.dashboard .main-title .back-text {
  margin-bottom: 16px;
}

.dashboard .main-title .rt-btns button:last-child {
  margin-right: 0;
}

.dashboard .main-title h2 .edit-icon {
  margin-left: 16px;
  width: 28px;
}

.analytic-cards .card {
  border-radius: 10px;
  background: #FFF;
  box-shadow: 0px 0px 54px 0px rgba(75, 75, 75, 0.15);
  border: unset;
  padding: 30px 20px;
}

.analytic-cards {
  margin-bottom: 30px;
}

.analytic-cards .card {
  margin-bottom: 30px;
}

.analytic-cards .card img {
  width: 70px;
  height: 70px;
  margin-right: 20px;
}

.analytic-cards .card .text .title {
  font-size: 20px;
  font-weight: 500;
  color: #000;
  margin-bottom: 12px;
  display: inline-block;
}

.analytic-cards .card .text {
  width: calc(100% - 102px);
}

.analytic-cards .card .text-btm .value {
  font-size: 28px;
  font-weight: 700;
  color: #000;
  display: inline-block;
}

.analytic-cards .card .text-btm .percentage {
  color: #7BAFD4;
  border-radius: 70px;
  background: #EAF2F8;
  font-size: 16px;
  font-weight: 500;
  padding: 6px 8px;
  display: inline-block;
}

.analytic-cards .sub-title {
  font-family: 'Kanit', sans-serif;
  font-size: 24px;
  margin-bottom: 10px;
}

/* ------------------ dashboard --------------------------- */

/* ------------------ campaign --------------------------- */

.nav-pills .nav-link {
  background-color: unset !important;
  color: #878787 !important;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 20px;
  padding: 0;
}

.nav-pills .nav-link img {
  opacity: 0.6;
}

.nav-pills .nav-link.active img {
  opacity: 1;
}

.nav-pills .nav-item:last-child .nav-link {
  margin-bottom: 0;
}

.nav-pills .nav-link.active {
  color: #13294B !important;
}

.tabs-wrap .tabs-inner:first-child {
  margin-bottom: 40px;
}

.tabs-title {
  font-size: 20px;
  color: #13294B;
  margin-bottom: 30px;
  border-bottom: 1px solid;
  display: inline-block;
}

.campaign-box {
  background: #F9F9F9;
  border-radius: 10px;
  padding: 20px 40px;
  text-align: center;
}

.campaign-box .title {
  font-size: 22px;
  margin-bottom: 16px;
  background: transparent;
}

.campaign-box .sub-title {
  font-size: 18px;
  color: #000;
  margin-bottom: 30px;
  background: transparent;
}

.table-listing.single-analytics .table-responsive {
  height: auto;
}

.table-listing.single-analytics table {
  margin-bottom: 0;
}

.table-listing.single-analytics table td {
  border-bottom: 0;
}

.analytics-listing table thead {
  background: #ededed;
}

.table-listing.single-analytics {
  padding: 0;
}

.analytics-listing {
  background: #fff;
  margin-top: 20px;
  max-height: 200px;
  overflow-y: auto;
  width: 100%;
}

.analytics-listing table {
  width: 100%;
}

.filter-bar {
  margin-bottom: 30px;
}

.filter-bar .search-bar input.form-control {
  border-radius: 6px;
  border: unset;
  padding: 12px 16px 12px 44px;
  font-size: 18px;
  color: #000;
  margin-bottom: 0;
  width: 332px;
  background: #F9F9F9;
}

.filter-bar .search-bar svg {
  position: absolute;
  top: 18px;
  left: 16px;
  color: #13294B;
}

.filter-bar .select-box label {
  margin-bottom: 0 !important;
  margin-right: 10px;
  font-size: 16px;
}

.filter-bar select.form-select {
  width: 220px;
  margin-bottom: 0;
  padding: 12px 16px;
}

.filter-bar .filter-box {
  margin-right: 30px;
}

.modal-content {
  background: #F9F9F9 !important;
}

.modal-content .modal-header {
  border-bottom: unset;
  padding: 30px 40px;
}

.modal-content .modal-header .modal-title {
  font-family: 'Kanit', sans-serif;
  font-size: 34px;
}

.modal-content .modal-footer {
  border-top: unset;
  padding: 30px 40px;
}

.modal-body {
  padding: 0px 40px !important;
}


.modal-body .content p {
  text-align: center;
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 0;
}

.modal-dialog {
  max-width: 800px !important;
}

.modal-header .btn-close {
  background-color: #EDEDED;
  border-radius: 100%;
  width: 38px;
  height: 38px;
  opacity: 1;
}

.css-t3ipsp-control {
  background-color: transparent !important;
  border-color: #13294B !important;
  padding: 10px 16px;
  font-size: 16px;
  box-shadow: unset !important;
}

.css-13cymwt-control {
  background-color: transparent !important;
  border-color: #13294B !important;
  padding: 10px 16px;
  font-size: 16px;
}

.select-folder-dropdown .css-13cymwt-control {
  background-color: #FFF !important;
  border-color: #E6E6E6 !important;
}

.css-1jqq78o-placeholder {
  color: #13294B !important;
}

.css-1xc3v61-indicatorContainer {
  color: #13294B !important;
}

.table-listing {
  background: #F9F9F9;
  border-radius: 10px;
  padding: 30px 30px;
}


.table-listing .form-switch {
  margin-right: 40px;
}

.table-listing .form-switch .form-check-input {
  width: 3em;
  border-radius: 2em !important;
}

.table-listing .table-responsive {
  height: calc(100vh - 460px);
  overflow-y: auto;
}

.table-listing thead {
  position: sticky;
  top: 0;
}

.table-listing td {
  background-color: transparent !important;
  padding: 20px 10px !important;
  white-space: nowrap;
  vertical-align: middle;
}

.table-listing input.form-check-input {
  border: 1px solid #13294b;
}

.table-listing .email {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 8px;
  color: #13294B;
}

.table-listing .date {
  font-size: 16px;
  color: #878787;
  margin-bottom: 0;
  white-space: nowrap;
}

.table-listing .number {
  font-size: 18px;
  font-weight: 500;
  color: #13294B;
  text-align: center;
}

.table-listing .status {
  font-size: 16px;
  color: #878787;
  text-align: center;
  font-weight: 500;
  margin-bottom: 0;
}

.table-listing .border-badge {
  border-radius: 30px;
  border: 1px solid #00B05C;
  padding: 12px 20px;
  font-size: 16px;
  font-weight: 500;
  color: #00B05C;
  display: inline-block;
  width: 110px;
  text-align: center;
}

.table-listing .border-badge.sms {
  border: 1px solid #CB0101;
  color: #CB0101;
}

.table-listing .border-badge svg {
  margin-right: 4px;
}

.table-listing .status-badge {
  border-radius: 30px;
  background: #a5c5dd;
  color: #fff;
  padding: 12px;
  width: 164px;
  display: inline-block;
  text-align: center;
}

.table-listing .status-badge.blue {
  background: #3B6AE3;
}

.table-listing .status-badge.green {
  background: #34BD3A;
}

.text-green {
  color: #34BD3A;
}

.table-listing .status-badge.red {
  background: #CB0101;
}

.text-red {
  color: #CB0101;
}

.table-listing .form-select {
  padding: 12px 16px;
  margin-bottom: 0;
  width: 140px;
}

.sending-fields {
  margin-bottom: 40px;
}

.sending-fields .card {
  border: unset;
  background: #F9F9F9;
  border-radius: 10px;
  padding: 30px 30px;
  margin-bottom: 30px;
}

.sending-fields .card:last-child {
  margin-bottom: 0;
}

.sending-fields .card .text span {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 12px;
  display: block;
  color: #13294B;
}

.sending-fields .card .text p {
  font-size: 18px;
  color: #000;
  margin-bottom: 0;
}

.sending-fields .card .form-select {
  width: 230px;
  background-color: transparent;
  border: 1px solid #13294B;
  text-overflow: ellipsis;
  overflow: hidden !important;
  white-space: nowrap;
  padding-right: 30px;
}

 .sending-fields .card .choose-library-dropdown .form-select {
  width: 100%;
}

input.dark-border-input {
  border: 1px solid #13294B;
  background-color: transparent;
}

.sending-fields .card .subject input.form-control {
  border-color: #13294B;
  background-color: unset;
}

.sending-fields .card .subject input.form-control::placeholder {
  color: #000;
}

.sending-fields .card .item {
  margin-left: 16px;
}

.campaign-dropdown-list {
  min-width: 230px;
}

.sending-fields .card .btn-border-black {
  background-color: transparent;
  border: 1px solid #13294B;
  padding: 16px 16px;
  font-size: 16px;
  color: #000;
  width: 230px;
}

.sending-fields .card .btn-border-black:hover {
  background-color: #13294B;
  border: 1px solid #13294B;
  color: #fff;
}

.add-subject .btns button:first-child {
  margin-right: 10px;
}

.add-subject .subject-field {
  flex: 1;
  margin-right: 20px;
}

.template-options {
  margin-bottom: 40px;
}

.template-options .card {
  border: unset;
  background: #F9F9F9;
  border-radius: 10px;
  text-align: center;
  padding: 30px 40px;
}

.template-options .card img {
  width: 60px;
  margin: 0 auto;
  margin-bottom: 30px;
}

.template-options .card .text {
  margin-bottom: 20px;
}

.template-options .card .text span {
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 12px;
  display: block;
  color: #13294B;
}

.template-options .card .text p {
  font-size: 16px;
  color: #000;
  margin-bottom: 0;
}

.template-preview .card {
  border-radius: 10px;
  background: #F9F9F9;
  padding: 30px 30px;
  border: unset;
  overflow-y: auto;
  max-height: calc(100vh - 480px);
  margin-bottom: 30px;
}

.template-preview .card img {
  width: 100%;
}

.stripo__header{
  display: flex;
  justify-content: flex-end;
}

/* ------------------ campaign --------------------------- */
/* ------------------ journey builder start --------------------------- */
/* .start-journey .item {
  background: #F4F4F4;
  border-radius: 4px;
  padding: 20px;
  text-align: center;
  display: inline-block;
  font-size: 18px;
  color: #13294B;
}

.start-journey .item p:last-child {
  margin-bottom: 0;
}

.start-journey .item p .text-blue {
  color: #078BEA;
}

.start-journey .item-line {
  padding: 8px;
}

.start-journey .btn-sm {
  background: #9EF9CD;
  font-size: 14px;
  padding: 4px 14px;
  border-radius: 4px;
  position: absolute;
  left: 90px;
  top: 10px;
  cursor: pointer;
}

.start-journey .btn-sm.red {
  background: #FFCFCF;
  left: unset;
  right: 90px;
}

.start-journey .item-yes {
  position: relative;
  display: inline-block;
}

.start-journey .condition-left {
  margin-right: 40px;
}

.start-journey .item-yes .fa-circle-plus {
  color: #7BAFD4;
  font-size: 32px;
  position: absolute;
  top: 60px;
  left: 44px;
  cursor: pointer;
}

.start-journey .item-yes.no {}

.start-journey .condition-right {
  margin-right: 0;
  margin-left: 40px;
}

.start-journey .item-yes.no .fa-circle-plus {
  left: unset;
  right: 44px;
} */

.journey-rules-box {
  border: 1px solid #E6E6E6;
  border-radius: 10px;
  padding: 20px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  position: fixed;
  right: 20px;
  bottom: 20px;
  background: #fff;
  width: 300px;
  z-index: 10;
}

.opacityZero{
  opacity: 0;
}

.cursor-pointer{
  cursor: pointer;
}

.journey-rules-box .close-icon {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
}

.journey-rules-box .title {
  font-size: 18px;
  color: #7BAFD4;
  font-weight: 500;
}

.journey-rules-box .item {
  margin-bottom: 30px;
}

.journey-rules-box .item:last-child {
  margin-bottom: 0;
}

.journey-rules-box ul li {
  padding: 16px 30px;
  border: 1px solid #E6E6E6;
  border-radius: 6px;
  font-size: 18px;
  cursor: pointer;
  margin-bottom: 20px;
}

.journey-rules-box ul li:last-child {
  margin-bottom: 0;
}

.journey-rules-box ul li svg {
  margin-right: 10px;
}
/* ------------------ journey builder end --------------------------- */

/* ------------------contact list------------------------- */
/* .table-listing.contact-list {
 min-height: calc(100vh - 340px);
} */

.table-listing.contact-list .table-responsive {
  height: calc(100vh - 470px);
  overflow-y: auto;
}

.table-listing.contact-list .table-responsive {
  height: calc(100vh - 470px);
  overflow-y: auto;
}

.table-listing th {
  background-color: transparent !important;
  padding: 20px 10px !important;
  border-bottom: 0;
  color: #13294b !important;
  font-weight: 500;
  white-space: nowrap;
  vertical-align: middle;
}

.table-listing.contact-list.all-contacts th:nth-child(3) {
  width: auto;
}

.table-listing.contact-list th:nth-child(3) {
  width: 36%;
}

.table-listing th svg.fa-sort path {
  color: #13294B;
}

.table-listing thead {
  background: #E9F0F4;
}

.table-listing .actions svg {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.table-listing table .form-check-input {
  margin-top: 0;
}

/* .table-listing .actions svg:last-child {
 margin-right: 0;
} */

.table-listing .actions svg.fa-circle-xmark path {
  color: #CB0101;
}

.table-listing .actions svg.fa-pen-to-square path {
  color: #3B6AE3;
}

.table-listing .actions svg.fa-eye path {
  color: #2D9E1B;
}


.table-listing .tag-name {
  background: #f1f1f1;
  border-radius: 30px;
  padding: 8px 28px;
  color: #13294b;
  font-weight: 500;
  margin-right: 10px;
}

.table-listing .tag-name:last-child {
  margin-right: 0;
}

.advance-search button {
  background: transparent;
  border: unset;
  text-align: left;
  padding: 0;
}

.advance-search .dropdown-toggle::after {
  display: none;
}

.advance-search .dropdown-menu {
  width: 100%;
  border-radius: 10px;
  border: 0;
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.15);
  padding: 20px;
}

.advance-search h2 {
  font-size: 28px;
  font-family: 'Kanit', sans-serif;
  margin-bottom: 20px;
}

.advance-search select.form-select {
  width: 100%;
  margin-bottom: 0;
}

.advance-search .box {
  border-radius: 4px;
  border: 1px solid #E6E6E6;
  padding: 16px;
}

.advance-search .item {
  margin-bottom: 20px;
}

.advance-search .fields {
  border-radius: 4px;
  background: #F9F9F9;
  padding: 10px;
}

.advance-search .form-group {
  margin-bottom: 0;
}

.advance-search .choose-option {
  margin-bottom: 20px;
}

.advance-search .choose-option .text {
  display: inline-flex;
}

.advance-search .choose-option .text span {
  background: #E6E6E6;
  color: #13294B;
  padding: 8px 16px;
  font-weight: 500;
}

.advance-search .choose-option .text span.active {
  background: #7BAFD4;
  color: #fff;
}

.advance-search svg {
  color: #7BAFD4;
  width: 30px;
  height: 30px;
}

.advance-search button .fa-plus {
  width: 16px;
  height: 16px;
  color: #fff;
}

.pagination {
  margin-top: 30px;
  justify-content: flex-end;
  margin-bottom: 0;
}

.page-link {
  color: #13294b !important;
  background-color: unset !important;
}

.page-link:hover {
  color: #fff !important;
  background-color: #13294b !important;
}

.active>.page-link, .page-link.active {
  border-color: #13294b !important;
}

.info-box {
  border-radius: 20px;
  border: 1px solid #E6E6E6;
  background: #F9F9F9;
  padding: 30px;
}

.info-box li {
  margin-bottom: 32px;
}

.info-box li:last-child {
  margin-bottom: 0;
}

.locations-item span {
  color: #13294B !important;
  font-size: 18px !important;
  font-weight: 500;
}

.info-box .text-lt {
  flex-basis: 50%;
  color: #878787;
  font-size: 16px;
}

.info-box .text-rt {
  color: #13294B;
  font-size: 16px;
  flex-basis: 50%;
}

.info-box .filter-title {
  margin-bottom: 20px;
  border-bottom: 1px solid #DCDCDC;
  padding-bottom: 20px;
}

.info-box .filter-title .title {
  font-size: 20px;
  font-weight: 500;
  color: #13294B;
}

.info-box .filter-title select.form-select {
  width: 164px;
  padding: 12px 16px;
}

.info-box .filter-title .options div {
  margin-right: 20px;
}

.info-box .filter-title .options div:last-child {
  margin-right: 0;
}

.activity-list li {
  margin-bottom: 30px;
}

.activity-list li:last-child {
  margin-bottom: 0;
}

.activity-list li img {
  min-width: 50px;
  height: 50px;
  margin-right: 20px;
}

.activity-list li span {
  font-size: 16px;
  margin-bottom: 6px;
  display: block;
  font-weight: 500;
  color: #878787;
}

.activity-list li p {
  color: #13294B;
  margin-bottom: 0;
  font-weight: 500;
  font-size: 18px;
}

.info-box .filter-title label {
  margin-bottom: 0 !important;
  font-size: 18px;
  font-weight: 500;
  margin-right: 10px;
}

.add-contact {
  border-radius: 10px;
  background: #F9F9F9;
  padding: 30px;
  margin-bottom: 30px;
}

.action-badges button {
  border: 1px solid;
  font-size: 16px;
  color: #CB0101;
  border-radius: 60px;
  padding: 12px 30px;
  font-weight: 500;
  background-color: unset;
  margin-right: 12px;
}

.action-badges button:hover {
  background-color: #ffe6e6;
}

.action-badges button:last-child {
  margin-right: 0;
}

.action-badges button.edit {
  color: #3B6AE3;
  background-color: unset;
}

.action-badges button.edit:hover {
  background-color: #e0e9ff;
}

.action-badges button svg {
  margin-left: 10px;
  background-color: unset;
}

.info-cards {
  margin-bottom: 30px;
}

.info-cards .card {
  border-radius: 10px;
  background: #F9F9F9;
  border: unset;
  padding: 30px 30px;
}

.info-cards .card .title {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 20px;
}

.info-cards .card .text span.count {
  font-size: 50px;
  color: #13294B;
  margin-bottom: 10px;
  display: block;
}

.info-cards .card .text .sub-text {
  font-weight: 500;
}

.choose-tags.dropdown button {
  background: transparent;
  border: unset;
  text-align: left;
  padding: 0;
  width: 100%;
}

.choose-tags .dropdown-menu {
  padding: 14px;
  width: 100%;
}

.choose-tags.dropdown .form-group {
  margin-bottom: 0;
}

.choose-tags.dropdown input {
  cursor: pointer;
}

.choose-tags.dropdown .dropdown-toggle::after {
  display: none;
}

.choose-tags.dropdown .btn:focus-visible {
  box-shadow: none;
}

.choose-tags.dropdown .btn-check:checked+.btn, .btn.active, .btn.show, .btn:first-child:active, :not(.btn-check)+.btn:active {
  background-color: unset !important;
  border-color: unset !important;
}

.choose-tags.dropdown .fa-plus {
  position: absolute;
  bottom: 20px;
  right: 16px;
}

/* -------------segments------------------------ */
.review-segment .segment-name {
  font-size: 26px;
  margin-bottom: 20px;
}

.review-segment .count {
  font-size: 30px;
  margin-right: 10px;
  font-weight: 500;
}

.review-segment .contact-count span.text {
  margin-right: 10px;
  font-weight: 500;
  color: #000;
}

.review-segment .contact-count span.date-time {
  font-style: italic;
  color: #878787;
}

.review-segment ul li {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 10px;
}

.review-segment ul li .fa-greater-than {
  width: 12px;
  height: 12px;
  margin: 0 6px;
}

/* ---------------settings--------------------- */
.table-listing.users-list .form-select {
  width: 240px;
}

.table-listing.users-list .table-responsive {
  height: calc(100vh - 380px);
  overflow-y: auto;
}

.filter-bar.row-item {
  margin-bottom: 0;
}

.filter-bar.row-item .search-bar input.form-control {
  padding: 16px 16px 16px 44px;
  margin-right: 20px;
}

.filter-bar.row-item .search-bar svg {
  top: 22px;
}

.add-link a {
  font-size: 18px;
  font-weight: 500;
  text-decoration: underline !important;
}

.add-location-box {
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.15);
  padding: 30px;
  margin-bottom: 15px;
}

.locations-modal .modal-dialog {
  max-width: 840px !important;
}

.locations-modal .text-tp {
  font-size: 16px;
  color: #878787;
  display: block;
  margin-bottom: 6px;
}

.locations-modal .text-btm {
  font-size: 18px;
  color: #13294B;
  display: block;
}

.locations-modal ul li {
  margin-bottom: 30px;
}

.locations-modal .accordion-item {
  background-color: unset;
  border: unset;
  border-radius: 0 !important;
  border-bottom: 1px solid #DEDEDE;
}

.locations-modal .accordion-button {
  background-color: unset !important;
  border: unset;
  padding: 20px 0;
  font-size: 20px;
  font-weight: 500;
  color: #13294B;
}

.locations-modal .accordion-body {
  padding: 10px 0;
}

.locations-modal .accordion-button svg {
  margin-right: 20px;
}

.locations-modal .accordion-item.active {
  background: #7BAFD4;
}

.locations-modal .accordion-button:focus {
  box-shadow: unset !important;
}

.setting-domains .info-box h2 {
  font-size: 22px;
  margin-bottom: 20px;
  color: #13294B;
}

.setting-domains .info-box p {
  color: #13294B;
  font-size: 16px;
  margin-bottom: 30px;
}

/* ------------------------ analytics -------------------------- */
.analytics-bar.filter-bar select.form-select {
  padding: 16px 16px;
  margin-right: 20px;
}

.analytics-bar.filter-bar .sub-title {
  font-family: 'Kanit', sans-serif;
  font-size: 24px;
}

.analytics-table .analytics-bar.filter-bar {
  margin-bottom: 20px;
}

.analytics-table .text {
  margin-right: 10px;
}

.rate-badge {
  border-radius: 50px;
  padding: 4px 8px;
}

.rate-badge.light-green {
  background: rgba(0, 176, 92, 0.30);
  margin-right: 10px;
}

.rate-badge.light-red {
  background: rgba(203, 1, 1, 0.20);
  margin-left: 10px;

}

/* ------------------journey------------------------------------- */
.table-listing.journeys .table-responsive {
  height: calc(100vh - 380px);
  overflow-y: auto;
}

.radio-labels .content {
  margin-bottom: 12px;
}

.radio-labels .form-check {
  margin-right: 20px;
}

.radio-labels .form-check:last-child {
  margin-right: 0;
}

.radio-labels select {
  margin-bottom: 10px;
}

.radio-labels select:last-child {
  margin-bottom: 0;
}

/* .error-box {
 background: #ffebeb;
 color: red;
 padding: 14px;
 border-radius: 10px;
 margin-bottom: 30px;
 text-align: center;
} */
.text-danger.error-text {
  color: #a73205 !important;
  font-weight: 500;
}

.error-box {
  background-color: #ffedee;
  color: #a73205;
  padding: 10px;
  margin-bottom: 40px;
  border: 1px solid #f5c6cb;
  border-radius: 4px;
}

.form-control.is-invalid, .was-validated .form-control:invalid {
  border-color: #a73205 !important;
  border: 2px solid;
}

.form-select.is-invalid {
  border-color: #a73205 !important;
  border: 2px solid;
}

.invalid-feedback {
  color: #a73205 !important;
  font-weight: 500;
}

.form-check-input.is-invalid, .was-validated .form-check-input:invalid {
  border-color: #13294B !important;
}

.form-check-input.is-invalid~.form-check-label {
  color: #13294B !important;
}

/* Segments css */
.review-segment {
  margin-bottom: 40px;
}

.review-contact-segment.table-listing .table-responsive {
  height: auto;
}

.review-segment .segment-name {
  font-size: 26px;
  margin-bottom: 20px;
}

.review-segment .count {
  font-size: 30px;
  margin-right: 10px;
  font-weight: 500;
}

.review-segment .contact-count span.text {
  margin-right: 10px;
  font-weight: 500;
  color: #000;
}

.review-segment .contact-count span.date-time {
  font-style: italic;
  color: #878787;
}

.review-segment ul li {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 10px;
}

.review-segment ul li .fa-greater-than {
  width: 12px;
  height: 12px;
  margin: 0 6px;
}
/* 
.error-box {
 background-color: #f7f7f7;
 color: #241c15a6;
 padding: 10px;
 margin-bottom: 40px;
 border: 1px solid #241c1526;
 border-radius: 4px;
 border-left: 3px solid #a73205;
} */

.custom-fields.table-listing .table-responsive {
  height: unset;
  overflow-y: unset;
}
.text-type {
  margin-bottom: 10px;
    display: block;
    color: #000;
    background: #f0f0f0;
    padding: 6px 10px;
    border-radius: 4px;
    word-wrap: break-word;
}
.text-value {
  word-wrap: break-word;
}
.custom-radio-btns .form-check-input {
  border-radius: 50% !important;
}
.custom-radio-btns .form-check-input:checked {
  background-color: #7bafd3 !important;
  border-color: #7bafd3 !important;
}
.show.dropdown.template-custom-dropdown {
  border-radius: 6px;
  border: 1px solid #E6E6E6;
  background-color: #fff;
}
.btn.templateDropdown {
  border-radius: 6px;
  border: 1px solid #E6E6E6;
  padding: 16px 16px;
  font-size: 16px;
  color: #000 !important;
  background-color: #fff !important;
  text-align: left;
}
.btn.templateDropdown:hover {
  color: #000;
  background-color: #fff !important;
  border: 1px solid #E6E6E6;
}
.btn.templateDropdown.dropdown-toggle::after {
  display: none;
}
.dropdown:focus-visible {
  background-color: #fff !important;
}
.dropdown:focus-visible .btn.templateDropdown {
  color: #000;
}
.btn.show:active:focus-visible {
  border: none !important;
}
.btn.show {
  border: none !important;
}
.dropdown-toggle:active {
  border: none !important;
}
.dropdown-item.active, .dropdown-item:active {
  background-color: #ffffff !important;
  color: #000 !important;
}
.inside-toggles {
  padding: 10px !important;
}
.inside-toggles .dropdown-toggle::after {
  /* position: absolute;
  left: 10px;
  top: 16px;
  transform: rotate(270deg); */
  display: none;
}
.inside-toggles .dropdown-toggle {
  width: 100%;
  text-align: left;
  /* padding-left: 30px; */
}
.inside-toggles .dropdown-toggle.show::after {
  transform: rotate(0deg);
}

.inside-toggles .dropdown-menu {
  max-height: 200px;
  overflow-y: auto;
  margin-left: 10px;
}

.tag-dropdown .dropdown-toggle::after {
  display: none;
}

.hide-dropdown-arrow .dropdown-toggle::after {
  display: none;
}

.csv-download-btn-style.btn.btn-primary {
  height: 30px;
  font-size: 12px;
  margin-left: 10px;
  background-color: #13294c;
  border-color: #13294c;
  margin-bottom: 3px;
}

.tag-dropdown .dropdown-menu.show {
  max-height: 190px;
  overflow: auto;
}

.centerHeader.modal-header {
  justify-content: center;
}

.centerHeader.modal-header button {
  position: absolute;
  right: 30px;
}
.journey-builder-radios .form-check {
  margin-right: 100px;
}
.templateCustomDropdown .form-select:disabled {
  background-color: #e9ecef !important;
  border-color: #e9ecef !important;
  opacity: 1;
}

.upload-image {
  position: absolute;
  right: 7px;
  top: 4px;
  border-radius: 4px;
}

.add-link .btn-add {
  border: 1px solid;
  border-radius: 6px;
  padding: 10px 12px;
  cursor: pointer;
  display: inline-block;
  margin: 6px;
}

.add-link .btn-add:hover {
  background: #13294B;
  color: #fff;
}

/* ------------------------------ Responsive CSS Starts ------------------------------------- */
@media (max-width: 1799.98px) {
  .sidebar {
    width: 310px;
    padding: 20px 20px 100px 20px;
  }

  .main-wrapper {
    width: calc(100% - 310px);
    margin-left: 310px;
  }

  .analytic-cards .card {
    padding: 20px 10px;
  }

  .analytic-cards .card .text-btm .value {
    font-size: 24px;
  }
}

@media (max-width: 1679.98px) {
  .analytic-cards .text-btm {
    display: block !important;
  }

  .analytic-cards .card .text-btm .value {
    display: block;
    margin-bottom: 10px;
  }

  .filter-bar .search-bar input.form-control {
    width: 240px;
  }

  .filter-bar select.form-select {
    width: 180px;
  }

  .table-listing {
    padding: 20px 20px;
  }

  .stepper span.border-line {
    width: 260px;
  }

  .info-box .filter-title {
    display: block !important;
  }

  .info-box .filter-title .title {
    margin-bottom: 10px;
    display: block;
  }
}

@media (max-width: 1419.98px) {
  .filter-bar select.form-select {
    width: 140px;
  }

  .stepper span.border-line {
    width: 200px;
  }

  .contacts-top-btns.main-title {
    display: block !important;
  }

  .dashboard .main-title .rt-btns button {
    margin-bottom: 16px;
  }
}

@media (max-width: 1399.98px) {
  /* .stepper span.border-line {
   width: 300px;
 } */

  .analytic-cards .text-btm {
    display: flex !important;
  }

  .analytic-cards .card .text-btm .value {
    display: inline-block;
    margin-bottom: 0;
  }

  .navbar .search-bar input.form-control {
    width: 272px;
  }

  .select-location select.form-select {
    width: 178px;
  }

  .select-location label {
    font-size: 16px;
  }

  .navbar .dropdown-toggle.nav-link {
    font-size: 16px;
  }

  .dashboard .main-title {
    margin-bottom: 20px;
  }

  .dashboard .main-title svg.fa-bars {
    width: 20px;
    margin-right: 10px;
  }

  .dashboard .main-title svg.fa-bars path {
    color: #13294B;
  }

  .sidebar ul li {
    padding: 14px 24px;
    border-radius: 10px;
    margin-bottom: 8px;
  }

  .sidebar ul li span {
    font-size: 18px;
  }

  .sidebar ul li svg {
    margin-right: 16px;
  }

  .show-dropdown a {
    font-size: 16px;
    margin-bottom: 16px;
  }

  .navbar-brand img {
    margin-left: 0;
  }

  .navbar .search-bar {
    margin-right: 20px;
  }

  .select-location {
    margin-right: 20px;
  }

  .tabs-wrap {
    display: flex;
  }

  .tabs-inner .nav-pills {
    flex-direction: row !important;
  }

  .tabs-inner {
    margin-right: 40px;
  }

  .tabs-inner .nav-pills a {
    margin-right: 30px;
    margin-bottom: 0;
  }

  .info-box .filter-title label {
    font-size: 16px;
  }

  .info-box .filter-title select.form-select {
    width: 144px;
  }
}

@media (max-width: 1199.98px) {
  .card-bg {
    height: unset;
    padding: 40px 20px;
  }

  .lt-text {
    padding: 70px 24px;
  }

  .rt-content {
    padding: 40px 116px 30px;
  }

  .signup-process {
    height: unset;
    padding: 40px 20px;
  }

  .table-listing .status-badge {
    width: 130px;
  }


  .filter-bar.d-flex {
    display: block !important;
  }

  .filter-bar .search-bar input.form-control {
    margin-bottom: 16px;
  }

  .stepper span.border-line {
    width: 140px;
  }

  .sending-fields .card {
    margin-bottom: 30px !important;
  }

  .template-options .card {
    margin-bottom: 30px;
  }

  .sending-fields {
    margin-bottom: 10px;
  }

  .info-box {
    margin-bottom: 30px;
  }

  .info-cards .card {
    margin-bottom: 20px;
  }

  .sending-fields .content.d-flex {
    display: block !important;
  }

  .sending-fields .content .text {
    margin-bottom: 16px;
  }

  .filter-bar.analytics-bar.d-flex {
    display: flex !important;
  }

  .filter-bar.top-block.d-flex {
    display: flex !important;
  }

  .filter-bar.top-block .search-bar input.form-control {
    margin-bottom: 0;
  }
}

@media (max-width: 991.98px) {
  .stepper span.border-line {
    width: 170px;
  }

  .main-wrapper {
    width: 100%;
    margin-left: 0;
  }

  .navbar-collapse form {
    justify-content: center;
  }

  .sidebar {
    top: 48px;
  }

  .offcanvas-lg.show {
    width: 310px !important;
    visibility: visible ! important;
    transition: transform 0.3s ease-in-out !important;
  }

  .offcanvas-lg {
    width: 310px !important;
  }

  .offcanvas-header {
    justify-content: end !important;
    /* background: #13294b; */
    background: #f2f4fb;
  }

  /* .offcanvas-header button {
    filter: invert(1);
  } */

  .offcanvas-body {
    padding: 0 !important;
  }

  .sending-fields .content.d-flex {
    display: flex !important;
  }

  .sending-fields .content .text {
    margin-bottom: 0;
    margin-right: 40px;
  }

  .sending-fields .card .form-select {
    width: 200px;
  }

  .sending-fields .card .btn-border-black {
    width: 200px;
  }
}

@media (max-width: 767.98px) {
  .lt-text {
    padding: 40px 10px;
  }

  .login .title {
    font-size: 32px;
  }

  .rt-content {
    padding: 40px 26px 30px;
  }

  .login .title {
    font-size: 34px;
  }

  .stepper li {
    margin-right: 20px;
  }

  .stepper span.border-line {
    width: 110px;
  }

  .signup-process .card.verify {
    padding: 60px 60px;
  }

  .card.verify .form-title h2 {
    font-size: 26px;
    margin-bottom: 12px;
  }

  .card.verify .form-title p {
    font-size: 16px;
    line-height: unset;
  }

  /* .navbar .search-bar input.form-control {
   width: 200px;
 }

 .select-location select.form-select {
   width: 150px;
 } */



  .tabs-wrap {
    display: block;
  }

  .sending-fields .card {
    padding: 20px 20px;
  }

  .stepper .step {
    min-width: 40px;
    height: 40px;
    line-height: 38px;
    font-size: 18px;
  }

  .stepper .step-name {
    font-size: 16px;
    margin-top: 10px;
  }

  /* .sending-fields .card .text {
   flex: 38% 1;
 } */

  .nav-options.d-flex {
    display: block !important;
  }

  .navbar .search-bar {
    margin-right: 0;
  }

  .navbar .search-bar input.form-control {
    width: 100%;
    margin-bottom: 14px;
  }

  .location-profile {
    justify-content: center;
  }

  .dashboard .main-title .rt-btns button {
    margin-bottom: 8px;
    margin-right: 8px;
  }

  .sending-fields .content.d-flex {
    display: block !important;
  }

  .sending-fields .content-sm.d-flex {
    display: block !important;
  }

  .sending-fields .content-sm .text {
    margin-bottom: 16px;
  }

  .sending-fields .select-boxes {
    display: block !important;
  }

  .sending-fields .card .item {
    margin-right: 0;
    margin-bottom: 16px;
  }

  .sending-fields .card .item:last-child {
    margin-bottom: 0;
  }

  .sending-fields .content .text {
    margin-bottom: 16px;
    margin-right: 0;
  }

  .sending-fields .card .form-select {
    width: 100%;
  }

  .dashboard .main-title.sm-block {
    display: block !important;
  }

  .filter-bar.analytics-bar.d-flex.sm-block {
    display: block !important;
  }

  .main-title.main-title-block {
    display: block !important;
  }

}


@media (max-width: 575.98px) {
  .login .title {
    font-size: 24px;
  }

  .rt-content h2 {
    font-size: 24px;
    margin-bottom: 30px;
  }

  .rt-content img {
    display: none;
  }

  .box-check {
    margin-bottom: 40px;
  }

  .input-form {
    margin-bottom: 60px;
  }

  .lt-text img {
    margin-bottom: 14px;
    width: 50px;
  }

  .login input.form-control {
    padding: 12px 16px;
    /* margin-bottom: 20px; */
  }

  select.form-select {
    padding: 12px 16px;
  }

  .btn-theme {
    padding: 10px 20px !important;
    font-size: 16px !important;
  }

  .signup-process .card {
    padding: 20px;
  }

  .stepper span.border-line {
    width: 40px;
  }

  h2.card-title {
    font-size: 24px;
  }

  .form-title h2 {
    font-size: 20px;
  }

  .card.verify img {
    width: 115px;
    margin-bottom: 30px;
  }

  .card.verify .form-title h2 {
    font-size: 20px;
    margin-bottom: 12px;
  }

  .card.verify .form-title p {
    font-size: 14px;
    line-height: unset;
  }

  .signup-process .card.verify {
    padding: 40px 40px;
  }

  .filter-bar .search-bar input.form-control {
    width: 100%;
  }

  .filter-bar .select-box .d-flex {
    display: block !important;
    flex: 1;
  }

  .filter-bar .select-box label {
    margin-bottom: 6px !important;
    font-size: 14px;
  }

  .filter-bar select.form-select {
    width: 100%;
  }

  .dashboard .main-title h2 {
    font-size: 22px;
  }

  .dashboard .main-title svg {
    width: 14px;
  }

  .tabs-title {
    font-size: 16px;
    margin-bottom: 20px;
    font-weight: 500;
  }

  .tabs-inner {
    margin-bottom: 30px;
    margin-right: 0;
  }

  .nav-pills .nav-link {
    font-size: 16px;
  }

  .campaign-box {
    padding: 40px 40px;
  }

  .campaign-box .title {
    font-size: 18px;
    margin-bottom: 12px;
  }

  .campaign-box .sub-title {
    font-size: 14px;
    margin-bottom: 20px;
  }

  .table-listing .email {
    font-size: 16px;
  }

  .table-listing .date {
    font-size: 14px;
  }

  .table-listing .number {
    font-size: 16px;
  }

  .table-listing .status {
    font-size: 14px;
  }

  .table-listing .border-badge {
    padding: 8px 20px;
  }

  .table-listing .status-badge {
    padding: 10px;
  }

  .table-listing .form-select {
    padding: 10px 16px;
    font-size: 14px;
  }

  .sending-fields .card .d-flex {
    display: block !important;
  }

  .sending-fields .card .text {
    margin-bottom: 20px;
  }

  .subject-field input {
    margin-bottom: 20px;
  }

  .sending-fields .card .text span {
    font-size: 18px;
    margin-bottom: 4px;
  }

  .sending-fields .card .text p {
    font-size: 16px;
  }

  .template-options .card {
    padding: 20px 20px;
  }

  .template-options .card .text span {
    font-size: 20px;
  }

  .template-options .card .text p {
    font-size: 14px;
  }

  .info-box .filter-title .options {
    display: block !important;
  }

  .info-box .filter-title .options div {
    margin-bottom: 10px;
  }

  .info-box .filter-title select.form-select {
    width: 100%;
  }

  .info-box .filter-title .options div:last-child {
    margin-bottom: 0;
  }

  .info-box .filter-title label {
    white-space: nowrap;
  }

  .activity-list li img {
    min-width: 40px;
    height: 40px;
    margin-right: 12px;
  }

  .activity-list li span {
    font-size: 14px;
  }

  .activity-list li p {
    font-size: 16px;
  }

  .info-box .text-lt {
    flex-basis: 40%;
    margin-right: 20px;
  }

  .info-box .text-rt {
    flex-basis: 60%;
  }

  .modal-content .modal-header {
    padding: 30px 20px;
  }

  .modal-body {
    padding: 0px 20px !important;
  }

  .modal-content .modal-footer {
    padding: 30px 20px;
  }

  .modal-content .modal-header .modal-title {
    font-size: 24px;
  }

  .modal-header .btn-close {
    width: 24px;
    height: 24px;
    font-size: 14px;
  }

  .advance-search .form-group {
    margin-bottom: 10px;
  }

  .advance-search h2 {
    font-size: 20px;
    margin-bottom: 10px;
  }

  .add-contact {
    padding: 20px;
  }

  .radio-labels .content {
    display: block !important;
  }

  .radio-labels .content>label {
    margin-bottom: 10px !important;
  }

  .analytics-bar.filter-bar select.form-select {
    margin-right: 0;
    margin-bottom: 10px;
  }

  .info-box {
    padding: 20px;
  }

  .info-box li {
    margin-bottom: 26px;
    display: block !important;
  }

  .info-box .text-lt {
    display: block;
  }

  .filter-bar.top-block.d-flex {
    display: block !important;
  }

  .filter-bar.top-block .search-bar input.form-control {
    margin-bottom: 16px;
  }
}

@media (max-width: 426.98px) {
  .select-location label {
    display: none;
  }
}

@media (max-width: 414.98px) {
  .rt-content {
    padding: 40px 10px 30px;
  }

  .form-check label {
    font-size: 14px;
  }

  .box-check p {
    font-size: 14px;
  }

  .btm-text a {
    display: block;
  }

  .stepper .step {
    min-width: 38px;
    height: 38px;
    line-height: 36px;
    font-size: 18px;
  }

  .dashboard .main-title {
    display: block !important;
  }

  .dashboard .main-title button {
    margin-top: 16px;
  }

  .tabs-inner .nav-pills a {
    margin-right: 20px;
  }

  .campaign-box {
    padding: 40px 20px;
  }

  .stepper li {
    margin-right: 0px;
  }

  .dashboard .contacts-top-btns.main-title button {
    margin-top: 0;
  }

  .dashboard .contacts-top-btns.main-title .rt-btns {
    margin-top: 16px;
  }
}

.business-logo {
  width: 50px;
}

.numbers-tab .nav-link.active {
  border-bottom: 0;
  font-weight: 600;
  background-color: #13294B;
  color: #fff;
}

.numbers-tab .nav-link {
  color: #000;
  border: 1px solid #ccc;
}

.numbers-tab .nav-link:hover {
  background-color: #13294B;
  color: #fff;
}

.number-buy-filter {
  background: #efefef;
  border-radius: 10px;
  padding: 16px;
}

.custom-tel-input .react-tel-input .form-control {
  height: unset;
  width: 100%;
  border-color: #e6e6e6;
}

.custom-tel-input .react-tel-input .flag-dropdown {
  border: 1px solid #e6e6e6;
  background-color: #fff;
}

.csv-mapping-field {
  width: 25%;
}


/* ------------------------------ Responsive CSS Ends ------------------------------------- */
/* ------------------------------ Responsive CSS Ends ------------------------------------- */


/* ------------------------------ Responsive CSS Ends ------------------------------------- */