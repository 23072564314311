.mainHead {
    width: 100%;
    margin: 0 auto;
  }
  
  .chat {
    border: 1px solid #ccc;
    margin-bottom: 10px;
    border-radius: 10px;
  }
  
  .chatBody {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    height: calc(100vh - 400px);
    padding: 30px;
  }
  
  .send-txt-div, .received-txt-div {
    margin-bottom: 30px;
  }
  
  .sender-txt {
    background-color: #13294c ;
    color: #fff;
    padding: 10px;
    border-radius: 10px;
    max-width: 70%;
    margin-left: auto;
    position: relative;
  }
  
  .received-txt {
    background-color: #f1f1f1;
    padding: 10px;
    border-radius: 10px;
    max-width: 70%;
    margin-right: auto;
  }

  .info-icon {
    position: absolute;
    left: -25px;
  }
  
  .send-time, .received-time {
    font-size: 0.8em;
    color: #999;
    text-align: right;
    margin-right: 42px;
    padding-top: 10px;
  }
  
  .received-time {
    text-align: left;
    margin-left: 42px;
    margin-right: 0px;
  }
  
  .send {
    display: flex;
    align-items: center;
    padding: 10px;
  }

  .chat-header {
    background-color: #f7f7f7;
    color: black;
    border-radius: 10px 10px 0 0;
    padding: 15px;
    border-bottom: 1px solid #ccc;
  }

  .chat-header-image {
    border: 2px solid #7d7c7c;
    border-radius: 100%;
    padding: 4px;
    width: 35px;
    height: 35px;
}
  
  .chat .form-control {
    width: 100%;
    margin-right: 10px;
    border-radius: 10px;
  }

  .btn.refresh-chat-btn {
    padding: 16px 16px;
    background-color: #7bafd3;
    border: none;
    &:hover{
      background-color: #13294c;
    }
  }

  .btn.send-chat-btn {
    padding: 16px 16px;
    background-color: #13294c;
    border: none;
    &:disabled{
      background-color: #13294c;
      opacity: 0.6;
    }
  }