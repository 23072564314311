.templates-container {
  padding: 20px;
  text-align: center;
  height: calc(100vh - 320px);
  overflow-y: auto;
}

.cards-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.card-container {
  position: relative;
  width: 200px;
  height: 300px;
  margin: 10px;
  overflow: hidden;
  border: 1px solid #ccc;
  border-radius: 10px;
  transition: transform 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.card-container:hover {
  transform: scale(1.05);
}

.card-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  flex-grow: 1;
}

.card-name {
  width: 100%;
  background-color: #fff;
  text-align: center;
  padding: 10px;
  box-shadow: 0 -1px 5px rgba(0, 0, 0, 0.1);
  border-top: 1px solid #ccc;
}

.card-hover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transition: opacity 0.3s ease;
}

.card-container:hover .card-hover {
  opacity: 1;
}

.import-button {
  padding: 10px 20px;
  background: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
}
