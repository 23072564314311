:root {
  --line-color: #7BAFD4;
  --line-width: .1em;
  --gutter: 50px;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
}

:before,
*:after {
  box-sizing: border-box;
}

.tree {
  margin: 0 0 calc(var(--gutter) * 2);
  text-align: center;
}

.tree,
.tree ul,
.tree li {
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
}

.tree li.rootLi{
  padding: unset;
}

.tree,
.tree ul {
  display: table;
  width: 100%;
}

.tree li {
  display: table-cell;
  padding: var(--gutter) 0;
  vertical-align: top;
}

.tree li:before {
  content: "";
  left: 0;
  outline: solid calc(var(--line-width) /2) var(--line-color);
  position: absolute;
  right: 0;
  top: 0;
}

.tree li:first-child:before {
  left: 50%;
}

.tree li:last-child:before {
  right: 50%;
}

.trigger-highlight {
  border: 2px dashed #13294c !important;
  background: #eef5f9 !important;
}

.tree button {
  border-radius: .2em;
  margin: 0 calc(var(--gutter) / 2) var(--gutter);
  min-height: 2.1em;
  position: relative;
  z-index: 1;
  margin-bottom: 50px;
}

.tree [contenteditable] {
  cursor: text;
}

.tree .selected {
  border-color: #7bafd4;
  border-style: dashed;
  /* -webkit-box-shadow: 0 0 var(--gutter) var(--line-width) rgba(153, 0, 0, .3);
-moz-box-shadow: 0 0 var(--gutter) var(--line-width) rgba(153, 0, 0, .3);
box-shadow: 0 0 var(--gutter) var(--line-width) rgba(153, 0, 0, .3); */
}

.tree ul:before,
.tree button:before {
  outline: solid calc(var(--line-width) / 2) var(--line-color);
  content: '';
  height: var(--gutter);
  left: 50%;
  position: absolute;
  top: calc(calc(-1 * var(--gutter)) - calc(var(--line-width) / 2));
}

.tree>li {
  margin-top: 0;
}

.tree>li:before,
.tree>li:after,
.tree>li>button:before {
  outline: none;
}

.tree button {
  position: relative;
  background: #fff;
  border-radius: 4px;
  padding: 10px 20px;
  text-align: center;
  font-size: 18px;
  color: #13294B;
  border: 1px solid #ccc;
}

.tree button:focus,
.tree button:hover {
  outline: .1em dotted var(--line-color);
  outline-offset: -.5em;
}

.tree .fa-circle-plus {
  position: absolute;
  right: 80px;
  top: -70px;
  width: 30px;
  height: 30px;
  color: #7BAFD4;
  cursor: pointer;
}
.tree .icon-right .fa-circle-plus {
  right: unset;
  left: 80px;
}

.label-icons {
  position: relative;
  display: inline-block;
  min-width: 300px;
}

.btn-sm {
  background: #9EF9CD;
  font-size: 14px;
  padding: 4px 14px;
  border-radius: 4px;
  position: absolute;
  cursor: pointer;
  top: -70px;
  right: 0;
  width: max-content;
}

.btn-sm.red {
  background: #FFCFCF;
  top: -70px;
  left: 0;
  right: unset;
}

.add-point .dashed-btn {
  color: #fff;
  padding: 6px 6px;
  font-size: 14px;
  border-radius: 4px;
  position: absolute;
  right: 40px;
  top: -80px;
  width: max-content;
  display: list-item;
  background: #13294B;
  z-index: 9;
  display: none;
}

.add-point .dashed-btn.right {
  right: unset;
  left: 40px ;
}
.menu-icon-hover .dashed-btn {
  right: 0 !important;
}
.menu-icon-hover .dashed-btn.right {
  right: unset !important;
  left: 0 !important;
}

.add-point .add-icon:hover .dashed-btn {
  display: block;
  cursor: pointer;
}

.add-point .add-hover {
  display: block;
  position: relative;
}
.add-point .add-hover .menu-icon {
  position: absolute;
  top: 0;
  right: -18px;
  border: 1px solid #ccc;
  padding: 4px 0;
  width: 14px;
  height: 14px;
}
.add-point .add-hover button {
  margin-left: 0;
  margin-right: 0;
}

.add-point .add-hover .dashed-btn {
  top: -0;
  cursor: pointer;
}
.root-content svg {
  width: 30px;
    height: 30px;
    margin-right: 16px;
    border-right: 1px solid #ccc;
    padding-right: 16px;
}

.add-point .add-hover .menu-icon-hover:hover > .dashed-btn {
  display: block;
}
.start-journey {
  height: calc(100vh - 270px);
  overflow: auto;
}
.add-icon.center .fa-circle-plus {
  left: 0;
  right: 0;
  margin: auto;
  /* width: 50%; */
}
.root-content p {
  font-size: 14px;
  color: #545454;
}

.triggerActionIcon{
  position: unset !important; 
}

/* trigger modal start */
 
.lg .modal-dialog{
  max-width: 1100px !important;
}

.trigger-cards .card {
  border-radius: 10px;
  background: #FFF;
  box-shadow: 0px 0px 54px 0px rgba(75, 75, 75, 0.15);
  border: unset;
  padding: 30px 20px;
  margin-bottom: 30px;
}

.trigger-cards .card img {
  min-width: 40px;
  margin-right: 10px;
}

.trigger-cards .card .text-title {
  font-size: 18px;
  font-weight: 500;
}

.trigger-cards .card ul {
  min-height: 82px;
  padding-left: 20px !important;
  margin-top: 20px;
}

.trigger-cards .card ul li {
  list-style: disc;
  font-size: 16px;
  margin-bottom: 6px;
}

.trigger-cards .card ul li:last-child {
  margin-bottom: 0;
}

.jb-menu{
  position: absolute !important;
  top: 0;
  right: -18px;
}

.jb-menu .dropdown-toggle::after {
  display: none;
}


.jb-menu .dropdown-toggle:active {
  /* background-color: red !important; */
  color: #000 !important;
  outline: 1px solid #000 !important;
}

.jb-menu .dropdown-toggle.show {
  border: 1px solid #ccc !important;
  color: #000 !important;
}

.jb-menu .dropdown-toggle:hover {
  outline: none !important;
  border: 1px solid #ccc !important;
  background-color: #fff !important;
  color: #000;
}

.jb-menu .dropdown-toggle::before {
  display: none;
}

.jb-menu .dropdown-toggle {
  padding: 0px 4px;
  border-radius: 2px;
}
/* trigger modal end */