/* XLoader.css */

.x-loader-container {
    position: absolute;
    top: 50%;
    left: 50%;
}

.spinner-small {
    height: 1rem !important;
    width: 1rem !important;
    border-width: initial !important;
}

.spinner-medium {
    height: 2rem !important;
    width: 2rem !important;
    border-width: medium !important;
}

.spinner-large {
    height: 3rem !important;
    width: 3rem !important;
    border-width: thick !important;
}